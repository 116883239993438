import { Button, Typography, responsiveFontSizes, Grid, Box, Collapse, useTheme } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function AgoraaCardXS() {

    const theme = useTheme();
    const [DronaviaOpen, setDronaviaOpen] = useState(false);

    const resume = "DOMESTIA est  lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed augue semper porta. Mauris massa. Vestibulum lacinia arcu eget nulla. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur sodales ligula in libero."
    return (
        <Grid container onClick={() => setDronaviaOpen(!DronaviaOpen)} xs={12} sx={{ display: "flex", justifyContent: "space-between", flexDirection: "row", borderRadius: 2, backgroundColor: "#282F37", marginBottom: theme.spacing(2),  transition: "background-Color 1s", "&:hover": { backgroundColor: "#3b496e", } }}>
            <Grid item xs={9} sx={{ display: "flex", justifyContent: "left", flexDirection: "row", borderRadius: 2, p: 1, alignItems: "center" }}>
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "flex-start" }}>
                <Typography variant="h5" style={{ color: "#f2f2f2",  fontWeight: "bold", textAlign: "left" }}> Développeur Front-End </Typography>
                    <Typography variant="body1" style={{  color: "#12F7D6", fontWeight: "bold", textAlign: "left" }}> AGORAA </Typography>
                    <Typography variant="body2" style={{  color: "grey",  fontWeight: "bold", textAlign: "left" }}> Sep. 2023 - Mar. 2024 </Typography>
                </Grid>
            </Grid>
            <Grid item xs={3} sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "flex-end" }}>
                <KeyboardArrowDownIcon onClick={() => setDronaviaOpen(!DronaviaOpen)} sx={{ color: "#12F7D6", fontSize: "4rem", transform: DronaviaOpen ? 'rotate(180deg)' : 'none', transition: 'transform 0.3s ease-in-out', }} />
            </Grid>


            <Grid container>
                <Grid item xs={12} md={6} >
                    <Collapse in={DronaviaOpen}>
                        <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "flex-start", p: 1 }}>
                        <img src="https://yt3.googleusercontent.com/gPNUKW_l8pvkrYgl00Kpn0K5xEDnAP4j2bY9f3aQxWd35NsVpLyYnxWpD76hRsKgYGyABnghMg=s900-c-k-c0x00ffffff-no-rj" alt="me" style={{  height: 120, width: 120, borderRadius: 8, alignSelf: "center", justifySelf: "center"  }} />
                        </Box>
                        <Grid container direction="column" alignItems="flex-start">
                            <Typography variant="h6" style={{ paddingLeft: theme.spacing(4), paddingTop: theme.spacing(1), color: "#12F7D6", fontWeight: "bold", textAlign: "left" }}> Tache </Typography>
                            <Typography variant="h6" style={{ padding: theme.spacing(4), paddingTop: theme.spacing(1), color: "#f2f2f2", fontWeight: "bold", textAlign: "left", lineBreak: "auto" }}> {resume} </Typography>
                        </Grid>
                    </Collapse>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Collapse in={DronaviaOpen}>
                        <Grid container direction="column" alignItems="flex-start">
                            <Typography variant="h6" style={{ paddingLeft: theme.spacing(4), paddingTop: theme.spacing(4), color: "#12F7D6", fontWeight: "bold", textAlign: "left" }}> Skills </Typography>
                        </Grid>
                    </Collapse>
                </Grid>
            </Grid>

        </Grid>
    )
}