import { Button, Typography, responsiveFontSizes, Grid, Box, useTheme } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';

import fullstack from '../assets/fullstack.svg';
import mobile from '../assets/mobile.svg';
import web from '../assets/web.svg';

export default function Card({ techno, title, desc }) {

    const [open, setOpen] = useState(false);

    return (
        <Grid item style={{ borderRadius: 15 }} onClick={() => setOpen(!open)}  sx={{cursor: "pointer", m: { xs: 3, md: 4 }, backgroundColor: "#1A1D22", minHeight: 500, width: 300, transition: "background-color 0.2s, margin-Top 1s", "&:hover": {  marginTop: 1 }  }}>
            {techno === "fullstack" && <img src={fullstack} alt="fullstack" style={{ width: "100%", height: "auto", objectFit: "cover", borderRadius: 15 }} />}
            {techno === "mobile" && <img src={mobile} alt="mobile" style={{ width: "100%", height: "auto", objectFit: "cover", borderRadius: 15 }} />}
            {techno === "web" && <img src={web} alt="web" style={{ width: "100%", height: "auto", objectFit: "cover", borderRadius: 15 }} />}
            <Typography variant="h4" sx={{ color: "#fff", fontWeight: 'bold', mt: 2, textAlign: "center" }}>
                {title}
            </Typography>
            <Typography variant="h6" sx={{ color: "#fff", mt: 1, textAlign: "center", m: 2 }}>
                {desc}
            </Typography>
        </Grid>
    )
}