import { Button, Typography, responsiveFontSizes, Grid, Box, Collapse, useTheme } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DomestiaLogo from '../../assets/DomestiaSplash.png';
export default function DomestiaCardXS() {

    const theme = useTheme();
    const [DronaviaOpen, setDronaviaOpen] = useState(false);

    const resume = "DOMESTIA est  lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed augue semper porta. Mauris massa. Vestibulum lacinia arcu eget nulla. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur sodales ligula in libero."
    return (
        <Grid container onClick={() => setDronaviaOpen(!DronaviaOpen)} xs={12} sx={{ display: "flex", justifyContent: "space-between", flexDirection: "row", marginBottom: theme.spacing(2),  borderRadius: 2, backgroundColor: "#282F37", transition: "background-Color 1s", "&:hover": { backgroundColor: "#3b496e", } }}>
            <Grid item xs={9} sx={{ display: "flex", justifyContent: "left", flexDirection: "row", borderRadius: 2, p: 1 }}>
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "flex-start" }}>
                    <Typography variant="h5" style={{ color: "#f2f2f2", fontWeight: "bold", textAlign: "left" }}> Développeur mobile - Projet de fin d'études </Typography>
                    <Typography variant="body1" style={{ color: "#12F7D6", fontWeight: "bold", textAlign: "left" }}> Domestia </Typography>
                    <Typography variant="body2" style={{ color: "grey", fontWeight: "bold", textAlign: "left" }}> Sep. 2021 - Feb 2024 </Typography>
                </Grid>
            </Grid>
            <Grid item xs={3} sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "flex-end" }}>
                <KeyboardArrowDownIcon onClick={() => setDronaviaOpen(!DronaviaOpen)} sx={{ color: "#12F7D6", fontSize: "4rem", transform: DronaviaOpen ? 'rotate(180deg)' : 'none', transition: 'transform 0.3s ease-in-out', }} />
            </Grid>


            <Grid container>
                <Grid item xs={12} md={6} >
                    <Collapse in={DronaviaOpen}>
                        <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "flex-start", p: 1 }}>
                            <img src={DomestiaLogo} alt="me" style={{ height: 120, width: 120, borderRadius: 8, alignSelf: "center", justifySelf: "center" }} />
                        </Box>
                        <Grid container direction="column" alignItems="flex-start">
                            <Typography variant="h6" style={{ paddingLeft: theme.spacing(4), paddingTop: theme.spacing(1), color: "#12F7D6", fontWeight: "bold", textAlign: "left" }}> Tache </Typography>
                            <Typography variant="h6" style={{ padding: theme.spacing(4), paddingTop: theme.spacing(1), color: "#f2f2f2", fontWeight: "bold", textAlign: "left", lineBreak: "auto" }}> {resume} </Typography>
                        </Grid>
                    </Collapse>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Collapse in={DronaviaOpen}>
                        <Grid container direction="column" alignItems="flex-start">
                            <Typography variant="h6" style={{ paddingLeft: theme.spacing(4), paddingTop: theme.spacing(4), color: "#12F7D6", fontWeight: "bold", textAlign: "left" }}> Skills </Typography>
                        </Grid>
                    </Collapse>
                </Grid>
            </Grid>

        </Grid>
    )
}