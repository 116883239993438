import './App.css';
import { Theme, createTheme, ThemeProvider, Button, Typography, responsiveFontSizes, Grid, Box } from '@mui/material';
import React, {useState} from 'react';
import '@fontsource/space-mono';

import TopBar from './navigation/TopBar';
import { BrowserRouter as Router, Routes, Route, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Landing from './components/landing';
import Footer from './components/footer';
function App() {
  document.title = "Yann Julitte - Portfolio";
  const [darkMode, setDarkMode] = useState(true);

  let theme = createTheme({
    typography: {
      fontFamily: [
        'Space Mono',
      ].join(','),
    },
    palette: {
      mode: 'dark',
      primary: {
        main : '#1A1D22' 
      },
      secondary: {
        main: '#282F37'
      },
      tiertiary: {
        main: '#2a2a2a'
      },
      text: {
        primary:'#f2f2f2',
      },
    },
  });
  theme = responsiveFontSizes(theme);




  return (
    <ThemeProvider theme={theme}>
      <Router>
      <div className="App" style={{ minHeight: "100vh", width: "100%", overflowX: "hidden", backgroundColor: "#282F37"}}>
        <TopBar />
        <Routes >
          <Route path="/" element={<Landing />} />
          <Route path="/resume" element={<Typography> resume </Typography>} />
          <Route path="/contact" element={<Typography> contact </Typography>} />
        </Routes>
        <Footer />
      </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
