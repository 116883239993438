import { Button, Typography, responsiveFontSizes, Grid, Box, Collapse, useTheme } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import monolithe from '../../assets/monolithe.png';

export default function MonolitheCard() {

    const theme = useTheme();
    const [DronaviaOpen, setDronaviaOpen] = useState(false);

    const resume = "Création d'une application web front et back-end permettant la création et l'administration de parcours numériques immersifs pour les clients de Monolithe Studio. Création d'une application mobile en temps que proof of concept utilisant l'API de l'application web. Utilisation de React, Node.js, Express, MongoDB pour la partie web et React Native pour la partie mobile."
    return (
        <Grid container  onClick={() => setDronaviaOpen(!DronaviaOpen)} xs={12} sx={{ display: "flex", justifyContent: "space-between", flexDirection: "row", borderRadius: 2, backgroundColor: "#282F37", marginBottom: theme.spacing(2),  transition: "background-Color 1s", "&:hover": { backgroundColor: "#3b496e", } }}>
            <Grid item xs={9} sx={{ display: "flex", justifyContent: "left", flexDirection: "row", borderRadius: 2 }}>
                <img src={monolithe} alt="me" style={{ height: 120, width: 120, borderRadius: 8 }} />
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "flex-start" }}>
                    <Typography variant="h5" style={{ paddingLeft: theme.spacing(4), color: "#f2f2f2",  fontWeight: "bold", textAlign: "left", maxLines: 2 }}> Développeur Web Full-Stack & Mobile </Typography>
                    <Typography variant="body1" style={{ paddingLeft: theme.spacing(4), color: "#12F7D6", fontWeight: "bold", textAlign: "left" }}> Monolithe Studio </Typography>
                    <Typography variant="body2" style={{ paddingLeft: theme.spacing(4), color: "grey",  fontWeight: "bold", textAlign: "left" }}> Avr. 2022 - Jui. 2022 </Typography>
                </Grid>
            </Grid>
            <Grid item xs={3} sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "flex-end" }}>
                <KeyboardArrowDownIcon onClick={() => setDronaviaOpen(!DronaviaOpen)} sx={{ color: "#12F7D6", fontSize: "4rem", transform: DronaviaOpen ? 'rotate(180deg)' : 'none', transition: 'transform 0.3s ease-in-out', }} />
            </Grid>

            <Grid container sx={{ justifyContent: "left" }}>
                <Grid item xs={12} md={6} >
                    <Collapse in={DronaviaOpen}>
                        <Grid container direction="column" alignItems="flex-start" >
                            <Typography variant="h6" style={{ paddingLeft: theme.spacing(4), paddingTop: theme.spacing(4), color: "#12F7D6", fontWeight: "bold", textAlign: "left" }}> Tache </Typography>
                            <Typography variant="h6" style={{ padding: theme.spacing(4), paddingTop: theme.spacing(1), color: "#f2f2f2", fontWeight: "bold", textAlign: "left" }}> {resume} </Typography>
                        </Grid>
                    </Collapse>
                </Grid>
                <Grid item xs={12} md={6} sx={{minHeight: 0}}>
                    <Collapse in={DronaviaOpen}>
                        <Grid container direction="column"  alignItems="flex-start">
                            <Typography variant="h6" style={{ paddingLeft: theme.spacing(4), paddingTop: theme.spacing(4), color: "#12F7D6", fontWeight: "bold", textAlign: "left" }}> Skills </Typography>
                        </Grid>
                    </Collapse>
                </Grid>
            </Grid>

        </Grid>
    )
}


