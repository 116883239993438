import React from "react";
import './TopBar.css';
import { AppBar, Toolbar, Typography, Button, IconButton, Grid, Hidden, Menu } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import galaxy from "../assets/galaxy.svg";
import { useNavigate } from "react-router-dom";

export default function TopBar() {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState('home'); // Default to 'home'

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleNavigation = (page) => {
    if (page === "") {
      navigate("/");
      setCurrentPage('home');
     } // Set 'home' when navigating to the root
    else if (page === "home") {
      navigate("/");
      setCurrentPage('home');
    } else {
      navigate(`/${page}`);
      setCurrentPage(page);
    }
  };

  return (
    <AppBar position="static" sx={{ background: "#282F37", height: "70px", pl: 1, pr: 1, alignItems: "center" }}>
      <Toolbar display="flex" flexDirection="row" sx={{ height: "100%", width: "98%" }}>
        <Grid container alignItems="center" justifyContent="space-between" display={"flex"} flexDirection={"row"}>
          <Grid item alignItems="center" display={"flex"}>
            <img src={galaxy} alt="galaxy" style={{ height: "50px", animation: "spin 10s linear infinite" }} />
            <Typography variant="h5" component="div" sx={{ color: "#f2f2f2", ml: 2 }}>
              Yann Julitte
            </Typography>
          </Grid>
          <Hidden mdDown>
            <Grid item>
              <Button color="inherit" sx={{ textTransform: "none" }} onClick={() => handleNavigation("")}>
                <Typography variant="h6" textAlign="center" sx={{ color: currentPage === 'home' ? "#12F7D6" : "white" }}>À propos</Typography>
              </Button>
              <Button color="inherit" sx={{ textTransform: "none" }} onClick={() => handleNavigation("contact")}>
                <Typography variant="h6" textAlign="center" sx={{ color: currentPage === "contact" ? "#12F7D6" : "white" }}>Contact</Typography>
              </Button>
              <Button color="inherit" sx={{ textTransform: "none" }} onClick={() => handleNavigation("resume")}>
                <Typography variant="h6" textAlign="center" sx={{ color: currentPage === "resume" ? "#12F7D6" : "white" }}>CV</Typography>
              </Button>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid item alignItems="center" display={"flex"}>
              <IconButton sx={{ color: "text.primary" }} size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu} color="inherit">
                <MenuIcon />
              </IconButton>
              <Menu id="menu-appbar" anchorEl={anchorElNav} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {["A propos", "contact", "CV"].map((page) => (
                  <MenuItem key={page} onClick={() => handleNavigation(page)}>
                    <Typography color="text.primary" textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
          </Hidden>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

