import { Button, Typography, responsiveFontSizes, Grid, Box, Collapse, useTheme } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
export default function DronaviaCard() {

    const theme = useTheme();
    const [DronaviaOpen, setDronaviaOpen] = useState(false);

    const resume = "Développement d'une application en C# Windows Forms pour la mise à jour du firmware de télecommandes pour les parachutes commercialisés par Dronavia en utilisant Arduino CLI"
    return (
        <Grid container  onClick={() => setDronaviaOpen(!DronaviaOpen)} xs={12} sx={{ display: "flex", justifyContent: "space-between", flexDirection: "row", borderRadius: 2, backgroundColor: "#282F37", marginBottom: theme.spacing(2), transition: "background-Color 1s", "&:hover": { backgroundColor: "#3b496e", } }}>
            <Grid item xs={9} sx={{ display: "flex", justifyContent: "left", flexDirection: "row", borderRadius: 2 }}>
                <img src="https://www.lacameraembarquee.fr/img/cms/Marques/logo-page-marque-dronavia.jpg" alt="me" style={{ height: 120, width: 120, borderRadius: 8 }} />
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "flex-start" }}>
                    <Typography variant="h5" style={{ paddingLeft: theme.spacing(4), color: "#f2f2f2",  fontWeight: "bold", textAlign: "left" }}> Développeur C# </Typography>
                    <Typography variant="body1" style={{ paddingLeft: theme.spacing(4), color: "#12F7D6", fontWeight: "bold", textAlign: "left" }}> Dronavia </Typography>
                    <Typography variant="body2" style={{ paddingLeft: theme.spacing(4), color: "grey",  fontWeight: "bold", textAlign: "left" }}> Sep. 2020 - Nov. 2020 </Typography>
                </Grid>
            </Grid>
            <Grid item xs={3} sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "flex-end" }}>
                <KeyboardArrowDownIcon onClick={() => setDronaviaOpen(!DronaviaOpen)} sx={{ color: "#12F7D6", fontSize: "4rem", transform: DronaviaOpen ? 'rotate(180deg)' : 'none', transition: 'transform 0.3s ease-in-out', }} />
            </Grid>

            <Grid container  sx={{ justifyContent: "left" }}>
                <Grid item xs={12} md={6} >
                    <Collapse in={DronaviaOpen}>
                        <Grid container direction="column" alignItems="flex-start">
                            <Typography variant="h6" style={{ paddingLeft: theme.spacing(4), paddingTop: theme.spacing(4), color: "#12F7D6", fontWeight: "bold", textAlign: "left" }}> Tache </Typography>
                            <Typography variant="h6" style={{ padding: theme.spacing(4), paddingTop: theme.spacing(1), color: "#f2f2f2", fontWeight: "bold", textAlign: "left" }}> {resume} </Typography>
                        </Grid>
                    </Collapse>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Collapse in={DronaviaOpen}>
                        <Grid container direction="column" alignItems="flex-start">
                            <Typography variant="h6" style={{ paddingLeft: theme.spacing(4), paddingTop: theme.spacing(4), color: "#12F7D6", fontWeight: "bold", textAlign: "left" }}> Skills </Typography>
                        </Grid>
                    </Collapse>
                </Grid>
            </Grid>

        </Grid>
    )
}


