import { Button, Typography, responsiveFontSizes, Grid, Box, Collapse, useTheme } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import { GitHub, LinkedIn } from '@mui/icons-material';

export default function Footer() {
    const theme = useTheme();
    const year = new Date().getFullYear();
    return (
        <Grid container style={{ width: "100vw", overflowX: "hidden", backgroundColor: "#12F7D6" }}>
            <Grid container xs={12} sx={{ borderRadius: 2, height: "100%", width: "100%", alignItems: "center", justifyContent: { xs: "center" } }}>
                <Grid item xs={12}>
                    <Typography variant="body1" style={{ padding: theme.spacing(4), color: "#1A1D22", marginLeft: theme.spacing(0), fontWeight: "bold", textAlign: "center", justifyContent: "center" }}>
                        <span style={{ verticalAlign: 'middle' }}>©</span> Galaxian.dev 2024 - {year}.
                    </Typography>
                </Grid>
                <GitHub sx={{ color: "#1A1D22", fontSize: 40, cursor: "pointer", p: 1 }} />
                <LinkedIn sx={{ color: "#1A1D22", fontSize: 40, cursor: "pointer", p: 1 }} />
            </Grid>
        </Grid>
    )
}