import { Button, Typography, responsiveFontSizes, Grid, Box, useTheme, Hidden } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Card from './card';

import DronaviaCard from './xsTop/DronaviaCard';
import MonolitheCard from './xsTop/MonolitheCard';
import DomestiaCard from './xsTop/DomestiaCard';
import AgoraaCard from './xsTop/AgoraaCard';


import DomestiaCardXS from './xs/DomestiaCardXS';
import AgoraaCardXS from './xs/AgoraaCardXS';

export default function Landing() {
    const theme = useTheme();

    const FirstContainerRef = useRef(null);
    const SecondContainerRef = useRef(null);
    const ThirdContainerRef = useRef(null);

    const test = useRef(null);


    const [HasBeenScrolled, setHasBeenScrolled] = useState(false);
    const [HasBeenScrolled2, setHasBeenScrolled2] = useState(false);

    const [paragraphHeight, setParagraphHeight] = useState('auto');


    useEffect(() => {
        if (test.current) {
            const height = `${test.current?.offsetHeight}px`;
            setParagraphHeight(height);
        }
    }, [test.current?.offsetHeight]);


    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setHasBeenScrolled(true);
                } else {
                    setHasBeenScrolled(false);
                }
            },
            {
                root: null,
                rootMargin: "0px",
                threshold: 0.5,
            }
        );
        if (SecondContainerRef.current) {
            observer.observe(SecondContainerRef.current);
        }
    }, [SecondContainerRef]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setHasBeenScrolled2(true);
                } else {
                    setHasBeenScrolled2(false);
                }
            },
            {
                root: null,
                rootMargin: "0px",
                threshold: 0.5,
            }
        );
        if (ThirdContainerRef.current) {
            observer.observe(ThirdContainerRef.current);
        }
    }, [ThirdContainerRef]);


    const HandleScroll = () => {
        if (HasBeenScrolled) {
            window.scrollTo({ top: 0, behavior: "smooth" });
        } else {
            SecondContainerRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }

    const HandleScroll2 = () => {
        if (HasBeenScrolled2) {
            SecondContainerRef.current.scrollIntoView({ behavior: "smooth" });
        } else {
            ThirdContainerRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }

    const Paragraph = "Passionné par l'informatique depuis mon enfance, j'ai décidé d'intégrer Epitech en 2019 pour en faire mon métier. J'ai pu y apprendre les bases de la programmation, mais aussi à travailler en équipe et à gérer des projets. J'ai également pu y découvrir de nouvelles technologies, comme React, Node.js, ou encore Docker. Également passionné par les jeux vidéos, l'automobile et les parcs d'attractions, ma fascination pour ces domaines m'a conduit à approfondir mes connaissances et à rechercher des opportunités de carrière qui me permettraient de combiner mes passions avec ma formation en informatique. Cela m'a également motivé à continuer à me former et à rester à l'affût des dernières avancées technologiques, tout en restant ouvert à de nouvelles expériences professionnelles passionnantes."

    return (
        <>
            <Grid ref={FirstContainerRef} container direction="column" style={{ minHeight: "calc(100vh - 70px)", width: "100%", overflowX: "hidden", backgroundColor: "#282F37" }}>
                <Hidden mdDown>
                    <Grid item xs={12} md={4} sx={{ padding: { xs: theme.spacing(2), md: theme.spacing(8) } }}>
                        <Typography variant="h2" style={{ color: "#f2f2f2", fontWeight: "bold", }}> Votre vision, </Typography>
                        <Typography variant="h2" style={{ color: "#f2f2f2", marginLeft: theme.spacing(10), fontWeight: "bold" }}> notre création. </Typography>
                    </Grid>
                </Hidden>

                <Hidden mdUp>
                    <Grid item xs={12} sx={{ padding: theme.spacing(8) }}>
                        <Typography variant="h3" style={{ color: "#f2f2f2", fontWeight: "bold", textAlign: "center" }}> Votre vision, notre création. </Typography>
                    </Grid>
                </Hidden>

                <Grid item xs={12} sx={{ padding: { xs: theme.spacing(2), md: theme.spacing(8) }, alignSelf: "center", display: "flex" }}>
                    <Typography variant="h4" style={{ color: "#f2f2f2", fontWeight: "bold", textAlign: "center", alignSelf: "center" }}> Moi, c'est <span style={{ color: "#12F7D6" }}> Yann</span>. </Typography>
                </Grid>
                <Grid item xs={12} sx={{ padding: theme.spacing(2), alignSelf: "center", paddingBottom: theme.spacing(0), }}>
                    <Typography variant="h6" style={{ color: "#f2f2f2", fontWeight: "bold", textAlign: "center", alignSelf: "center" }}> Étudiant à <span style={{ color: "#12F7D6" }}> Epitech</span>. </Typography>
                </Grid>
                <Grid item xs={12} sx={{ alignSelf: "center", alignItems: "center", paddingTop: theme.spacing(1), bottom: 0, position: "absolute" }}>
                    <KeyboardArrowDownIcon onClick={HandleScroll} sx={{ color: "#12F7D6", fontSize: "4rem", transform: HasBeenScrolled ? 'rotate(180deg)' : 'none', transition: 'transform 0.3s ease-in-out' }} />
                </Grid>
            </Grid>
            <Grid ref={SecondContainerRef} container style={{ minHeight: "100vh", width: "100%", overflowX: "hidden", backgroundColor: "#1A1D22" }}>
                <Grid item xs={12}>
                    <Typography variant="h4" style={{ padding: theme.spacing(8), color: "#f2f2f2", marginLeft: theme.spacing(0), fontWeight: "bold" }}> A propos de <span style={{ color: "#12F7D6" }}> moi</span>. </Typography>
                </Grid>
                <Grid item xs={12} md={7} sx={{ padding: theme.spacing(8), paddingTop: theme.spacing(1) }}>
                    <Typography ref={test} variant="h6" style={{ color: "#f2f2f2", marginLeft: theme.spacing(0), fontWeight: "bold", }}> {Paragraph} </Typography>
                </Grid>
                <Grid item xs={12} md={5} sx={{ padding: theme.spacing(8), paddingTop: theme.spacing(1), display: 'flex', justifyContent: { xs: "center", md: "flex-end" } }}>
                    <img src="https://image.noelshack.com/fichiers/2024/04/7/1706463711-imresizer-1701078482276.jpg" alt="me" style={{ height: paragraphHeight, maxHeight: 500 }} />
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <KeyboardArrowDownIcon onClick={HandleScroll2} sx={{ color: "#12F7D6", fontSize: "4rem", transform: HasBeenScrolled2 ? 'rotate(180deg)' : 'none', transition: 'transform 0.3s ease-in-out' }} />
                </Grid>
            </Grid>


            <Grid ref={ThirdContainerRef} container style={{ minHeight: "100vh", width: "100vw", overflowX: "hidden", backgroundColor: "#1A1D22" }}>
                <Grid container xs={12} sx={{ m: { xs: 1, md: 4 }, p: { xs: 1, md: 4 }, backgroundColor: "#282F37", borderRadius: 2, height: "100%", width: "100%", alignItems: "center", justifyContent: { xs: "center", md: "space-between" } }}>
                    <Grid item xs={12}>
                        <Typography variant="h4" style={{ padding: theme.spacing(4), color: "#f2f2f2", marginLeft: theme.spacing(0), fontWeight: "bold", textAlign: "center" }}> Mes <span style={{ color: "#12F7D6" }}> skills</span>. </Typography>
                    </Grid>
                    <Card techno="fullstack" title="FULL-STACK" desc="Développement fullstack Express + React" />
                    <Card techno="web" title="WEB" desc="Développement de fronts et de widgets en React" />
                    <Card techno="mobile" title="MOBILE" desc={"Projet de fin d’études, deux ans et demi de React-Native"} />
                </Grid>
                <Grid container style={{ cursor: "pointer", width: "100vw", overflowX: "hidden", backgroundColor: "#1A1D22" }}>
                    <Grid item xs={12} sx={{ p: { xs: 1, md: 4 }, borderRadius: 2, alignItems: "center", justifyContent: { xs: "center", md: "space-between" } }}>
                        <Grid item xs={12}>
                            <Typography variant="h4" style={{ padding: theme.spacing(4), color: "#f2f2f2", marginLeft: theme.spacing(0), fontWeight: "bold", textAlign: "center" }}> Mes <span style={{ color: "#12F7D6" }}> Expériences</span>. </Typography>
                        </Grid>
                        <Hidden smDown sm>
                            <DronaviaCard />
                            <DomestiaCard />
                            <MonolitheCard />
                            <AgoraaCard />
                        </Hidden>
                        <Hidden smUp>
                            <DomestiaCardXS />
                            <AgoraaCardXS />
                        </Hidden>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}